// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: require('../../../../package.json').version + '-dev',
  authOptions: {
    clientId: 'client_id',
    clientSecret: 'client_secret'
  },
  urls: {
    crms: 'http://groupsvr.guava.africa:9275', //'http://groupsvr.guava.africa:9275',
    individualAuth: 'http://192.168.10.33:1007', // 'http://197.211.207.230:1007',
    individualProfile: 'http://192.168.10.33:1008' //'http://197.211.207.230:1008'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
