import { Injectable } from '@angular/core';
import * as tinycolor from 'tinycolor2';

@Injectable()
export class UserAvatarService {
  static getInitials(name: string): string | null {
    const [firstName, lastName] = name.split(' ');
    let first, last, initials;

    if (name && firstName && firstName !== '') {
      first = firstName[0];
      last = lastName && lastName !== '' ? lastName[0] : null;

      if (last) {
        initials = first + last.toUpperCase();
      } else {
        initials = first.toUpperCase();
      }

      return initials;
    } else {
      return null;
    }
  }

  static getNameColor(first: string, last: string) {
    const hue = ((first.length * last.length) % 36) * 10;
    const sat = 55 + (((first.length % last.length) / last.length) * 200);

    const lum = (first.length + last.length) / (first.length * last.length);
    let color = tinycolor({ h: hue, s: sat, l: lum });

    if (!color.isValid()) {
      color = tinycolor('#CCC');
    }
    return color;
  }

  public getAvatarFromName(name: string) {
    const initials = UserAvatarService.getInitials(name);

    if (initials == null) return null;

    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    canvas.width = 100;
    canvas.height = 100;
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d');

    const [first, last] = name.split(' ');
    const color = UserAvatarService.getNameColor(first, last);
    context.fillStyle = color.toHexString();
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '50px Arial';

    if (color.isLight()) {
      context.fillStyle = '#000';
    } else {
      context.fillStyle = '#FFF';
    }
    const { width: expectedTextWidth, emHeightAscent, emHeightDescent } = context.measureText(initials);
    const startPositionX = Math.ceil((canvas.width - expectedTextWidth) / 2);
    context.fillText(initials, startPositionX, 68);

    const data = canvas.toDataURL();
    document.body.removeChild(canvas);
    return data;
  }
}
