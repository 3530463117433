import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorModel, handleError, Hosts, HOSTS } from '@guava-pay/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User, UserToken } from '../../models/user';
import { Router } from '@angular/router';

@Component({
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss']
})
export class ChangePasswordPage implements OnInit {
  error?: ErrorModel;
  user: User;

  public form: FormGroup = this.formBuilder.group({
    newPassword: new FormControl('', [Validators.required]),
    confirmation: new FormControl('', [Validators.required])
  });

  constructor(private formBuilder: FormBuilder,
              @Inject(HOSTS) private hosts: Hosts,
              private http: HttpClient,
              private jwtHelperService: JwtHelperService,
              private uiLoader: NgxUiLoaderService,
              private title: Title,
              private router: Router) {
  }

  ngOnInit(): void {
    this.title.setTitle('Change Password | GuavaPay CRMS');
    const userToken: UserToken | any = this.jwtHelperService.decodeToken();
    this.user = userToken.user;
  }

  async submit() {
    try {
      this.uiLoader.start();
      const url = `${this.hosts.crms}/v1/user-account/update-password`;
      const content = {
        'confirmPassword': this.form.value.confirmation,
        'password': this.form.value.newPassword,
        'username': this.user.username
      };
      const response = await this.http.post<any>(url, content).toPromise();
      if (response.success === true) {
        this.router.navigateByUrl('/crms/profile');
      } else {
        this.error = {
          title: 'Password change failed',
          message: response.message
        };
      }
    } catch (e) {
      this.error = handleError(e);
    } finally {
      this.uiLoader.stop();
    }
  }

}
