<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-5 align-items-center">
          <h4 class="flex-grow-1">Support</h4>
        </div>
        <div class="w-100">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <h5>Thank you.</h5>
                <p>Your message has been sent to our support team.
                  They will contact you for feedback soon!</p>
                <p>
                  <a routerLink="/crms" class="btn btn-secondary px-4 text-white">OK</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
