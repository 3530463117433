import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { HOSTS, Hosts } from '@guava-pay/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'div[gpay-record-count-widget]',
  templateUrl: './company-batches-by-record-count.component.html',
  styleUrls: ['./company-batches-by-record-count.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'card shadow'
  }
})
export class CompanyBatchesByRecordCountComponent implements OnInit {
  error?: string;
  loading = false;
  data: Array<{ name: string; value: number }>;
  factor = 100;

  view: any[] = [500, 300];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Batch Volume';
  showYAxisLabel = true;
  yAxisLabel = 'Number of Batches';
  legendTitle = 'Range';

  colorScheme = {
    domain: ['#8DC63F', '#FF9027', '#AAAAAA']
  };

  constructor(private httpClient: HttpClient,
              @Inject(HOSTS)private hosts: Hosts,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const url = `${this.hosts.crms}/v1/dashboard/user/batch/get-by-range`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().append('interval', this.factor.toString());
    this.loading = true;
    this.httpClient.get<any>(url, { headers, params }).toPromise().then(response => {
      this.loading = false;
      const values: Array<{ sort: number; name: string; value: number }> = [];

      for (const key in response.result) {
        if (response.result.hasOwnProperty(key)) {
          const range = Number(key.valueOf()) * this.factor;
          values.push({ sort: range, name: `${range - this.factor}-${range}`, value: response.result[key] });
        }
      }
      this.logger.debug('Graph data:', values);
      this.data = values.sort((r1, r2) => {
        if (r1.sort === r2.sort) return 0;
        return r1.sort > r2.sort ? 1 : -1;
      });

    }, (reason) => {
      this.loading = false;
      this.error = reason;
    });
  }
}
