import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsbDashboardComponent } from './ssb-dashboard/ssb-dashboard.component';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { WidgetsModule } from './widgets/widgets.module';

@NgModule({
  imports: [CommonModule, WidgetsModule],
  declarations: [SsbDashboardComponent, CompanyDashboardComponent],
  exports: [SsbDashboardComponent, CompanyDashboardComponent]
})
export class DashboardsModule {
}
