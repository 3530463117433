<ng-container *ngIf="data">
  <div class="card-bordy px-5 py-3">
    <div class="w-100 d-flex align-items-end">
      <h6 class="card-title flex-grow-1">Batches by Record Count</h6>

      <ng-template #rangeSelector>
        <div class="form-group">
          <select [(ngModel)]="factor" (ngModelChange)="refresh()"
                  class="form-control form-control-sm">
            <option [disabled]="o === factor"
                    *ngFor="let o of [5,10,20,50,100,500,1000,5000]"
                    [value]="o">{{ o }}</option>
          </select>
        </div>
      </ng-template>

      <button class="btn btn-sm btn-default"
              [popover]="rangeSelector"
              popoverTitle="Change Range"
              [triggers]="'click'">
        <i class="fa fa-ellipsis-h"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [legendTitle]="legendTitle">
    </ngx-charts-bar-vertical>
  </div>
</ng-container>
