<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-5 align-items-center">
          <h4 class="flex-grow-1">Dashboard</h4>
        </div>
        <div class="w-100">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <ng-template #dynamic>
                  <div class="w-100">
                    LOADING...
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
