import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import {
  APP_VERSION,
  AUTH_OPTIONS_INJECTION_TOKEN,
  CoreModule,
  Hosts,
  HOSTS,
  TokenService
} from '@guava-pay/core';
import { AppRoutingModule } from './app-routing.module';
import { HomePage } from './pages/home/home.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  PB_DIRECTION,
  POSITION,
  SPINNER
} from 'ngx-ui-loader';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { APP_INITIALIZER } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DashboardsModule } from '@guava-pay/dashboards';
import { SupportPage } from './pages/support/support.page';
import { ReactiveFormsModule } from '@angular/forms';
import { SupportMessageAcknowledgementPage } from './pages/support-message-acknowledgement/support-message-acknowledgement.page';
import { SelectModulePage } from './pages/select-module/select-module.page';
import { CompanyDocumentsComponent } from './pages/company-documents/company-documents.component';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ' ',
  nullable: true,
  min: 0,
  max: 9999999,
  inputMode: CurrencyMaskInputMode.NATURAL
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#ffffff',
  fgsColor: '#ffffff',
  pbColor: '#ffffff',
  bgsPosition: POSITION.centerCenter,
  overlayColor: 'rgba(30, 85, 36, 0.65)',
  bgsSize: 40,
  bgsType: SPINNER.fadingCircle, // background spinner type
  fgsType: SPINNER.fadingCircle, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  //pbThickness: 5, // progress bar thickness,
  bgsOpacity: 0.5,
  blur: 0.5,
  text: 'PLEASE WAIT...',
  textColor: '#ffffff',
  textPosition: 'center-center'
};

const hostUrls: Hosts = {
  individualAuth: environment.urls.individualAuth,
  crms: environment.urls.crms,
  individualProfile: environment.urls.individualProfile
};

function getDomainUrl(host: string): string {

  return host.replace('http://', '').replace('https://', '');
}

export function jwtOptionsFactory(tokenService: TokenService) {
  return {
    tokenGetter: () => {
      return tokenService
        .getToken();
    },
    skipWhenExpired: true,
    allowedDomains: [
      getDomainUrl(hostUrls.crms),
      getDomainUrl(hostUrls.individualProfile),
      getDomainUrl(hostUrls.individualAuth)
    ]
  };
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService]
      }
    }),
    AppRoutingModule,
    DashboardsModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    HomePage,
    DashboardPage,
    SupportPage,
    SupportMessageAcknowledgementPage,
    SelectModulePage,
    CompanyDocumentsComponent
  ],
  providers: [
    { provide: APP_VERSION, useValue: environment.version },
    { provide: HOSTS, useValue: hostUrls },
    {
      provide: AUTH_OPTIONS_INJECTION_TOKEN,
      useValue: {
        clientId: environment.authOptions.clientId,
        clientSecret: environment.authOptions.clientSecret
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        permissionsService: NgxPermissionsService,
        jwtHelperService: JwtHelperService
      ) =>
        function() {
          const tokenObject = jwtHelperService.decodeToken();
          if (!tokenObject) return;
          return permissionsService.loadPermissions(tokenObject.authorities || []);
        },
      deps: [NgxPermissionsService, JwtHelperService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
