<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-3 align-items-center">
          <h4 class="flex-grow-1">Upload Profile Picture</h4>
        </div>
      </div>
    </div>
    <div class="row mb-2" *ngIf="user; else notFoundView">
      <div class="col-12">
        <input type="file" (change)="fileChangeEvent($event)"/>
      </div>
      <div class="col-6">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageURL]="savedImage"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="1 / 1"
          [resizeToWidth]="256"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
        <br/>
        <br/>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''"
                  tooltip="Rotate Left" (click)="rotateLeft()">
            <i class="fa fa-rotate-left"></i>
          </button>
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''"
                  tooltip="Rotate right" (click)="rotateRight()">
            <i class="fa fa-rotate-right"></i>
          </button>
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''"
                  tooltip=" Flip horizontal" (click)="flipHorizontal()">
            <i class="fa fa-arrows-h"></i>
          </button>
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''"
                  tooltip="Flip vertical" (click)="flipVertical()">
            <i class="fa fa-arrows-v"></i>
          </button>
        </div>
        &nbsp;&nbsp;
        <div class="btn-group btn-group-sm">
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''"
                  tooltip="Zoom Out" (click)="zoomOut()">
            <i class="fa fa-search-minus"></i>
          </button>
          <button class="btn btn-secondary text-white" [disabled]="!croppedImage && croppedImage!==''" tooltip="Zoom In"
                  (click)="zoomIn()">
            <i class="fa fa-search-plus"></i>
          </button>
        </div>
        <br/>
      </div>
      <div class="col-6">
        <div class="w-100 d-flex flex-column align-items-start">
          <img [src]="croppedImage || savedImage" alt="Select an image"
               [class.p-5]="!(croppedImage || savedImage) && (croppedImage || savedImage) !==''"
               class="bg-dark text-white img-thumbnail img-fluid border border-white">

          <button class="btn btn-primary px-4 my-2 text-white"
                  [disabled]="!croppedImage && croppedImage!==''"
                  (click)="uploadProfilePhoto()">
            Upload Image
          </button>

        </div>
      </div>
    </div>

    <ng-template #notFoundView>
      <div class="row position-relative">
        <div class="alert alert-warning">
          <h4 class="alert-heading">Failed to load your profile</h4>
          <p>Your session might have expired. Please re-log in and try again</p>
        </div>
      </div>
    </ng-template>

    <div class="row" *ngIf="error">
      <div class="col">
        <div class="alert alert-warning small" *ngIf="error">
          <div class="w-100 d-flex">
            <h6 class="alert-heading flex-grow-1 align-items-start">{{error.title}}</h6>
            <button (click)="error = null" type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p>{{ error.message }}</p>
        </div>
      </div>
    </div>

  </div>
</div>
