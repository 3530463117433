<div class="w-100 flex-grow-1 d-flex flex-column bg-primary">
  <section class="w-100">
    <img class="logo-img" src="assets/images/guavapay-sidebar-logo.png"/>
  </section>
  <section class="menu-section">
    <nav class="navbar navbar-default navbar-primary">
      <ul class="nav navbar-nav flex-column w-100 text-white text-capitalize">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/crms/dashboard">Dashboard</a>
        </li>

        <!-- <ng-container *ngxPermissionsOnly="['ADMIN']">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/crms/documents">Documents</a>
          </li>
        </ng-container> -->

        <ng-container *ngxPermissionsOnly="['ADMIN']">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/crms/batches">Records</a>
            <div class="sub-menu small" aria-expanded="false">
              <ul class="flex-column nav pl-4">
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/batches/new">Create New Batch</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/batches/upload">Import Bulk Records</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/batches/list">View Batches</a>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
        <li class="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['SSB_USER','SSB_ADMIN']">
          <a class="nav-link" routerLink="/crms/batches/responses">Responses</a>
        </li>
        <ng-container>
          <li class="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['ADMIN','SSB_ADMIN']">
            <a class="nav-link" routerLink="/crms/users">Users</a>
            <div class="sub-menu small" aria-expanded="false">
              <ul class="flex-column nav pl-4">
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/users">View Users</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/users/create">Add New User</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['ADMIN']">
            <a class="nav-link" routerLink="/crms/reports">Reports</a>
            <div class="sub-menu small" aria-expanded="false">
              <ul class="flex-column nav pl-4">
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/reports/company-batches-report">Company Batches</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link p-1" routerLink="/crms/reports/batches-by-date">Batches By Date</a>
                </li>
              </ul>
            </div>

          </li>
        </ng-container>
      </ul>
    </nav>
  </section>
</div>
