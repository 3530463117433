import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { HOSTS, Hosts } from '@guava-pay/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'div[gpay-throughput-by-company-widget]',
  templateUrl: './ssb-throughput-by-company.component.html',
  styleUrls: ['./ssb-throughput-by-company.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'card shadow' }
})
export class SsbThroughputByCompanyComponent implements OnInit {
  error?: string;
  loading = false;
  data: Array<{ name: string; series: Array<{ name: string; value: number }> }>;

  view: any[] = [500, 300];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Company';
  showYAxisLabel = true;
  yAxisLabel = 'Number of Batches';
  legendTitle = '';

  colorScheme = {
    domain: ['#8DC63F', '#FF9027', '#AAAAAA']
  };

  constructor(private httpClient: HttpClient,
              @Inject(HOSTS)private hosts: Hosts,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    const url = `${this.hosts.crms}/v1/dashboard/ssb/batch/get-by-company-and-processing-status`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loading = true;
    this.httpClient.get<any>(url, { headers }).toPromise().then(response => {
      this.loading = false;
      const values: Array<{ name: string; series: Array<{ name: string; value: number; }> }> = [];

      for (const key in response.result) {
        if (response.result.hasOwnProperty(key)) {
          const companyData = Object.assign({
            'SUBMITTED': 0,
            'CLOSED': 0
          }, response.result[key]);

          const companySeries: Array<{ name: string; value: number; }> = [];

          for (const status in companyData) {
            if (companyData.hasOwnProperty(status)) {
              companySeries.push({
                name: status,
                value: companyData[status]
              });
            }
          }

          values.push({ name: key, series: companySeries });
        }
      }
      this.logger.debug('Graph data:', values);
      this.data = values;

    }, (reason) => {
      this.loading = false;
      this.error = reason;
    });
  }
}
