import { Component, OnInit, Inject } from '@angular/core';
import { User, UserToken } from '../../models/user';
import { Title } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Hosts, HOSTS } from '@guava-pay/core';

@Component({
  templateUrl: './profile-photo.page.html',
  styleUrls: ['./profile-photo.page.scss']
})
export class ProfilePhotoPage implements OnInit {

  user: User;

  error?: {
    title: string;
    message: string;
  };

  savedImage:string = null;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  constructor(private title: Title,
              private jwtHelperService: JwtHelperService,
              @Inject(HOSTS)private hosts: Hosts,
              private http: HttpClient,
              private uiLoader: NgxUiLoaderService,
              private router: Router,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.title.setTitle('Update Profile Photo | GuavaPay CRMS');

    const userToken: UserToken | any= this.jwtHelperService.decodeToken();
    this.user = userToken.user;
    this.savedImage = this.loadSavedImage(userToken.user);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  loadSavedImage(user: User): string {
    if (user.imageUrl && user.imageUrl !== '')
      return `${this.hosts.crms}/v1${user.imageUrl}`;
    return null;
  }

  public async uploadProfilePhoto() {
    try {
      const imageFile = base64ToFile(this.croppedImage);
      const formData = new FormData();
      formData.append('profilePic ', imageFile);
      const url = `${this.hosts.crms}/v1/user-account/update-profile-image/${this.user.username}`;
      const headers = new HttpHeaders({ 'Accept': 'application/json' });
      this.uiLoader.start();

      const response = await this.http.post<any>(url, formData, { headers }).toPromise();
      if (response.success) {
        this.router.navigateByUrl('/crms/profile');
      } else {
        this.logger.warn('Error uploading photo:', response);
        this.error = {
          title: 'Failed to upload your profile photo',
          message: response.message
        };
      }
    } catch (e) {
      this.logger.warn(e);
      this.error = {
        title: 'There has been a problem while processing your request',
        message: 'Please try again later. If the error continues, contact your system administrator'
      };
    } finally {
      this.uiLoader.stop();

    }
  }
}

