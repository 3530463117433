import { Component } from '@angular/core';

@Component({
  selector: 'guava-pay-root',
  template: `
    <ngx-ui-loader></ngx-ui-loader>
    <div class="w-100 h-100 flex-grow-1">
      <router-outlet></router-outlet>
    </div>`
})
export class AppComponent {
}
