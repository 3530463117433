<div class="w-100 h-100 flex-grow-1 p-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="w-100 d-flex mb-3 align-items-center">
          <h4 class="flex-grow-1">Change Password</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form [formGroup]="form" class="w-100 mb-5">
          <div class="row">
            <!-- Password -->
            <div class="form-group col-lg-6">
              <div class="input-group input-group-lg rounded-0 border border-primary w-100">
                <div class="input-group-prepend col-lg-4 col-3  bg-primary text-left small rounded-0">
                    <span class="input-group-text rounded-0 border-0 bg-primary text-white small text-uppercase px-0">
                      New Password
                    </span>
                </div>
                <input type="password" class="form-control border-0 rounded-0"
                       formControlName="newPassword">
              </div>
            </div>

            <!-- Confirmation -->
            <div class="form-group col-lg-6">
              <div class="input-group input-group-lg rounded-0 border border-primary w-100">
                <div class="input-group-prepend col-lg-4 col-3  bg-primary text-left small rounded-0">
                    <span class="input-group-text rounded-0 border-0 bg-primary text-white small text-uppercase px-0">
                      Confirm password
                    </span>
                </div>
                <input type="password" class="form-control border-0 rounded-0"
                       formControlName="confirmation">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary px-4 text-white"
                      [disabled]="form.pristine || form.invalid"
                      (click)="submit()">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" *ngIf="error">
      <div class="col">
        <div class="alert alert-warning small" *ngIf="error">
          <div class="w-100 d-flex">
            <h6 class="alert-heading flex-grow-1 align-items-start">{{error.title}}</h6>
            <button (click)="error = null" type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p>{{ error.message }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
