import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Hosts, HOSTS } from '@guava-pay/core';

@Component({
  selector: 'gpay-company-batches-by-status',
  templateUrl: './company-batches-by-status.component.html',
  styleUrls: ['./company-batches-by-status.component.scss']
})
export class CompanyBatchesByStatusComponent implements OnInit {
  error?: string;
  loading = false;
  stats: { [status: string]: number; };

  selectedStatus?: string;

  constructor(private httpClient: HttpClient,
              @Inject(HOSTS)private hosts: Hosts) {
  }

  ngOnInit(): void {
    const url = `${this.hosts.crms}/v1/dashboard/user/batch/get-by-status`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loading = true;
    this.httpClient.get<any>(url, { headers }).toPromise().then(response => {
      this.loading = false;
      this.stats = Object.assign({
        'NEW': 0, 'AWAITING_PAYMENT': 0, 'COMPLETED': 0, 'CLOSED': 0
      }, response.result);
    }, (reason) => {
      this.loading = false;
      this.error = reason;
    });
  }

}
