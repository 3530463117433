<ng-container *ngIf="data">
  <div class="card-bordy px-5 py-3">
    <div class="w-100 d-flex align-items-end">
      <h6 class="card-title flex-grow-1">Batches by Company</h6>
    </div>
  </div>
  <div class="card-body">
    <ngx-charts-bar-vertical-2d
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [legendTitle]="legendTitle">
    </ngx-charts-bar-vertical-2d>
  </div>
</ng-container>
