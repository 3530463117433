<div class="w-100 d-flex flex-column flex-sm-row align-items-stretch">
  <div class="card w-50 shadow shadow-sm">
    <div class="card-bordy px-5 py-3">
      <div class="w-100 d-flex align-items-end">
        <h6 class="card-title flex-grow-1">Tracking overview</h6>
      </div>
    </div>
    <div class="card-body dashboard-card">
      <div class="w-100 d-flex flex-row align-items-center">
        <div class="flex-grow-1">
          <ul class="list-unstyled d-flex flex-column tracking-items-list">
            <li class="w-100 tracking-item"
                [class.active]="selectedStatus === status"
                *ngFor="let status of stats | keys">
              <a class="btn btn-default rounded-pill px-4" (click)="selectedStatus = status">
                <span>{{ status.replace('_', ' ') | titlecase }}</span>
              </a>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="selectedStatus">
          <div class="my-4  mx-3 separator"></div>
          <div class="flex-grow-1">
            <div class="w-100 tex-center d-flex flex-column align-items-center" *ngIf="selectedStatus">
              <span class="h1 card-block-value">{{ stats[selectedStatus] }}</span>
              <span class="text-muted my-1 small">Batches</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="w-100">
        <p class="text-muted small text-center">
          Select a status to view
        </p>
      </div>
    </div>
  </div>
  <div class="px-2"></div>
  <div class="d-flex flex-grow-1 flex-column align-items-stretch">
    <div class="card shadow flex-grow-1" [class.mb-2]="!isLast" *ngFor="let status of stats | keys; last as isLast">
      <div class="card-body dashboard-card">
        <div class="w-100">
          <progressbar [value]="stats[status]" type="secondary" [max]="stats | values | sum"></progressbar>
          <div class="w-100 d-flex flex-row align-items-end my-1">
            <h6 class="card-title text-primary m-0 flex-grow-1">{{ status.replace('_', ' ') | titlecase }}</h6>
            <span class="font-weight-bold text-dark m-0">{{ stats[status]}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

