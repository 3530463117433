<ng-container *ngIf="user">
  <div class="position-relative d-flex flex-row align-items-center" dropdown [autoClose]="true">
    <a dropdownToggle type="button" class="nav-link dropdown-toggle"
       aria-controls="dropdown-basic" (click)="false">
      {{ user.fullname }} <span class="caret"></span>
    </a>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu position-absolute"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem"><a class="dropdown-item" routerLink="/crms/profile">View Account</a></li>
      <li role="menuitem"><a class="dropdown-item" routerLink="profile/change-password">Change password</a></li>
      <li role="menuitem"><a class="dropdown-item" href="#" (click)="logout()">Logout</a></li>
    </ul>
    <div class="user-avatar">
      <img class="user-image" [src]="imageUrl">
    </div>
  </div>
</ng-container>
