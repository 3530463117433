<div class="w-100 h-100 d-flex flex-column flex-grow-1">
  <div class="flex-grow-1 w-100 d-flex align-items-stretch">
    <div class="sidebar-container bg-primary d-flex flex-column">
      <gpay-side-bar></gpay-side-bar>
      <div class="flex-grow-1"></div>
      <gpay-side-bar-footer></gpay-side-bar-footer>
    </div>
    <div class="flex-grow-1 d-flex flex-column bg-light align-items-stretch">
      <gpay-top-menu></gpay-top-menu>
      <div class="flex-grow-1 w-100 d-flex flex-column px-xl-5 px-lg-4 px-md-3 px-sm-2 px-xs-1 py-2 px-sm-1">
        <div class="d-flex justify-content-center">
          <img src="assets/images/psc_logo.png"  width= '100' height="100" alt="psc logo" />
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
