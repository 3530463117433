<div class="w-100">
  <header class="w-100 bg-white">
    <div class="d-flex w-100">
      <div class="flex-grow-1"><!-- Push Menu to the right --></div>
      <div class="bg-primary top-menu">
        <ul class="list-inline">
          <li class="list-inline-item"><a class="nav-link text-white" [routerLink]="['/auth','login']">Sign In</a></li>
          <li class="list-inline-item"><a class="nav-link text-white">Individual</a></li>
          <li class="list-inline-item"><a class="nav-link text-white">Enterprise</a></li>
          <li class="list-inline-item"><a class="nav-link text-white" routerLink="/crms">CRMS</a></li>
        </ul>
      </div>
    </div>
    <div class="w-100">
      <nav class="navbar navbar-default navbar-light bg-white">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/images/guavapay-logo-full.PNG" alt="GUAVAPAY"/>
        </a>
        <ul class="nav navbar-nav flex-row pull-right">
          <li class="nav-item active">
            <a class="nav-link text-decoration-none" href="https://guavatelecom.com/about/">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Solutions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-decoration-none" href="https://guavatelecom.com/contact/">Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><i class="fa fa-search text-secondary"></i></a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <main class="w-100">
    <section class="w-100">
      <img class="img-fluid w-100 mb-5" src="assets/images/home-page-section-1.png"/>
    </section>
    <section class="w-100 mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6 p-0">
            <img class="img-fluid w-100" src="assets/images/guavapay-home-section2.1.png"/>
          </div>
          <div class="col-sm-6 p-5">
            <h3 class="text-primary">Our Solution</h3>
            <p class="text-justify">GuavaPay is a digital payments platform that integrates Financial Institutions,
              Mobile Network Operators (MNOs) and Service Providers,
              offering consumers and enterprises a reliable and affordable payments gateway.
              GuavaPay was developed as a unique disruptor in a market that is now dominated by multi-tiered pricing
              regimes which unfortunately result in high transaction processing fees for the customers.
              Likewise, many Governments in developing nations are struggling to fully implement financial inclusivity
              policies due to the high cost of transaction processing fees.
              GuavaPay operates across mobile platforms such as Android, iOS and USSD.
              Our application offers both consumers and enterprises the flexibility of processing individual
              transactions and bulk payments
              in a secure and affordable environment.</p>
          </div>
          <div class="col-sm-6 p-5">
            <h3 class="text-primary">GuavaPay Mobile App</h3>
            <p class="text-justify">The GuavaPay Mobile Application allows our customers the flexibility of making
              payments,
              sending money and receiving money on the go. Our solution was designed to easily integrate into
              existing Mobile Network Operators (MNOs) mobile money wallets. This brings convinience to consumers
              and enterprises to be able to move money from their linked mobile wallets to their GuavaPay account
              as well as make instant transfers to and from other linked bank accounts. Customers can also have
              up to three (3) GuavaPay Debit Cards linked to their mobile application.
              GuavaPay Mobile App brings a new digital experience to transacting on the move.</p>
          </div>
          <div class="col-sm-6 p-0">
            <img class="img-fluid w-100" src="assets/images/guavapay-home-section2.2.png"/>
          </div>
          <div class="col-sm-6 p-0">
            <img class="img-fluid w-100" src="assets/images/guavapay-home-section2.3.png"/>
          </div>
          <div class="col-sm-6 p-5">
            <h3 class="text-primary">GuavaPay Online</h3>
            <p class="text-justify">
              The GuavaPay Online platform is an online web-based application which allows real-time internet banking
              and transaction processing for both consumers and enterprises.
              This allows the flexibility of bulk payments and also processing of critical payments such as salaries in
              a secure environment. GuavaPay Online is the premium bulk payments gateway that integrates into both
              service providers and financial institutions. GuavaPay Online delivers a secure real-time gateway for
              enterprise payments.
            </p>
          </div>
          <div class="col-sm-6 p-5">
            <h3 class="text-primary">GuavaPay CRMS</h3>
            <p class="text-justify">
              GuavaPay Consumer Rating Management System (CRMS) is a secure web-based application that processes credit
              applications for financial institutions against customer profiles in order to provide a detailed risk
              analysis. The system was developed firstly for government salary services bureaus which required banks and
              micro-finance institutions to validate credit worthiness of applicants prior to approval and disbursement
              of loans. GuavaPay CRMS is therefore constantly being further developed to integrate our other channels
              GuavaPay Mobile App and GuavaPay Online using artificial intelligence to ultimately deliver more detailed
              analytics on consumer banking and consumer risk.
            </p>
          </div>
          <div class="col-sm-6 p-0">
            <img src="assets/images/guavapay-home-section2.4.png" class="img-fluid w-100"/>
          </div>
        </div>
      </div>
    </section>
    <section class="w-100 bg-light">
      <div class="container-fluid p-3">
        <div class="col text-center p-5">
          <h3 class="text-primary mb-5">Sign Up and Get Started</h3>
          <a class="btn btn-lg btn-primary px-5 py-3 text-white rounded-pill"
             routerLink="/get-started">
            <span>Get Started</span>
          </a>
        </div>
      </div>
    </section>
  </main>
</div>
