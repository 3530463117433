<div class="w-100 bg-light px-xl-5 px-lg-4 px-md-3 px-sm-2 px-xs-1 py-2 px-sm-1">
  <nav class="navbar w-100 navbar-default navbar-light px-4">
    <div class="flex-grow-1"></div>
    <ul class="nav navbar-nav flex-row align-items-center">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/crms/support">Support</a>
      </li>
      <li class="nav-item">
        <gpay-user-badge></gpay-user-badge>
      </li>
    </ul>
  </nav>
</div>
