import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { HOSTS, Hosts } from '@guava-pay/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'div[gpay-batch-dates-widget]',
  templateUrl: './company-batches-by-date.component.html',
  styleUrls: ['./company-batches-by-date.component.scss'],
  host: {
    class: 'card shadow'
  }
})
export class CompanyBatchesByDateComponent implements OnInit {

  error?: string;
  loading = false;
  data: Array<{ name: string; value: number }>;
  view: any[] = [500, 300];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date';
  showYAxisLabel = true;
  yAxisLabel = 'Number of Batches';
  legendTitle = '';

  colorScheme = {
    domain: ['#8DC63F', '#FF9027', '#AAAAAA']
  };


  constructor(private httpClient: HttpClient,
              @Inject(HOSTS)private hosts: Hosts,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    const url = `${this.hosts.crms}/v1/dashboard/user/batch/get-by-date`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.loading = true;
    this.httpClient.get<any>(url, { headers }).toPromise().then(response => {
      this.loading = false;
      const values: Array<{ name: string; value: number }> = [];

      for (const key in response.result) {
        if (response.result.hasOwnProperty(key)) {

          values.push({ name: `${key}`, value: response.result[key] });
        }
      }
      this.logger.debug('Graph data:', values);
      this.data = values;

    }, (reason) => {
      this.loading = false;
      this.error = reason;
    });
  }
}
