import { Directive, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[sticky-top]',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'sticky-top'
  }
})
export class StickyTopDirective implements OnInit, OnDestroy {
  @HostBinding('class.shadow')
  floating: boolean = false;

  constructor(private element: ElementRef,
              private logger: NGXLogger) {

    logger.info('Native Element:', element.nativeElement);
    logger.info('Parent:', element.nativeElement.parent);
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.floating = window.scrollY > this.element.nativeElement.offsetHeight;
  };
}
