import { Component, OnInit, Inject } from '@angular/core';
import { TokenService, Hosts, HOSTS } from '@guava-pay/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NGXLogger } from 'ngx-logger';
import { User, UserToken } from '../../models/user';
import { UserAvatarService } from './user-avatar.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'gpay-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
  providers: [UserAvatarService]
})
export class UserBadgeComponent implements OnInit {

  user: User;
  imageUrl: string;

  constructor(private tokenService: TokenService,
              private jwtHelperService: JwtHelperService,
              private avatarService: UserAvatarService,
              private permissionsService: NgxPermissionsService,
              private router: Router,
              @Inject(HOSTS)private hosts: Hosts,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    // const  this.tokenService.getToken();
    const userToken: UserToken | any = this.jwtHelperService.decodeToken();
    this.logger.debug('Token:', userToken);
    this.user = userToken.user;
    this.imageUrl = this.getImageUrl(userToken.user);
  }

  getImageUrl(user: User): string {
    if (user.imageUrl && user.imageUrl !== '')
      return `${this.hosts.crms}/v1${user.imageUrl}`;
    return this.avatarService.getAvatarFromName(user.fullname);
  }

  logout() {
    this.tokenService.clear();
    this.permissionsService.flushPermissions();
    this.router.navigateByUrl('/');
  }
}
