import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Inject,
  ComponentFactory,
  ComponentRef,
  OnDestroy, AfterViewInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { CompanyDashboardComponent, SsbDashboardComponent } from '@guava-pay/dashboards';
import { NGXLogger } from 'ngx-logger';

@Component({
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage implements OnInit, AfterViewInit {

  @ViewChild('dynamic', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;
  factoryResolver: ComponentFactoryResolver;

  constructor(private title: Title,
              @Inject(ComponentFactoryResolver) factoryResolver,
              private logger: NGXLogger,
              private permissionsService: NgxPermissionsService) {
    this.factoryResolver = factoryResolver;
  }

  ngOnInit(): void {
    this.title.setTitle('Dashboard | GuavaPay CRMS');
  }

  ngAfterViewInit() {
    this.permissionsService.hasPermission('SSB_ADMIN').then(isSsbAdmin => {
      this.loadDashboard(isSsbAdmin);
    }, reason => {
      this.logger.warn('Loading permissions failed with reason', reason);
    });
  }

  private loadDashboard(isSsbAdmin: boolean) {
    const dashboardComponentFactory = isSsbAdmin ?
      this.factoryResolver.resolveComponentFactory(SsbDashboardComponent) :
      this.factoryResolver.resolveComponentFactory(CompanyDashboardComponent);
    this.attachComponent(dashboardComponentFactory);
  }

  private attachComponent<T>(componentFactory: ComponentFactory<T>) {
    //const component = componentFactory.create(this.viewContainerRef.parentInjector);
    const component = componentFactory.create(this.viewContainerRef.injector);
    this.viewContainerRef.clear();
    this.viewContainerRef.insert(component.hostView);
  }
}
